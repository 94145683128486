// import axios from 'axios';
import { parseRhythm } from 'components/Settings/helpers';
import * as types from 'types/progression-settings';
import * as parser from 'parser';
import * as helpers from './helpers';

export function getProgressionSettings(progression) {
  return {
    loop: progression.loop,
    transpose: progression.transpose,
    octave: progression.octave,
    time: progression.time,
    bpm: progression.bpm,
    instrument: progression.instrument,
    arpeggiateStyle: progression.arpeggiateStyle,
    customArpeggio: progression.customArpeggio,
    velocity: progression.velocity,
    ADSR: progression.ADSR,
    IADSR: progression.IADSR,
    timeSignature: progression.timeSignature,
    rhythm: progression.rhythm,
  };
}

export function setSyncChanges(newStateChanges) {
  return {
    type: types.SYNC_CHANGES,
    payload: {
      ...newStateChanges,
    },
  };
}

export function resetProgressionSettings() {
  return {
    type: types.RESET_SETTINGS,
  };
}

export const setInstrumentRequest = instrument => ({
  type: types.SET_INSTRUMENT_REQUEST,
  payload: {
    instrument,
  },
});

export const setInstrumentFailure = ({ error, detail }) => ({
  type: types.SET_INSTRUMENT_FAILURE,
  error,
  payload: {
    detail,
  },
});

export const setInstrumentSuccess = instrument => ({
  type: types.SET_INSTRUMENT_SUCCESS,
  payload: {
    instrument,
  },
});

const parseRequest = ({ progressionSettings }) => ({
  type: types.PARSE_REQUEST,
  payload: {
    progressionSettings,
  },
});

const parseFailure = ({ error, detail }) => ({
  type: types.PARSE_FAILURE,
  error,
  payload: {
    detail,
  },
});

const parseSuccess = ({ parsedProgression, delays, parsedLines }) => ({
  type: types.PARSE_SUCCESS,
  payload: {
    parsedProgression,
    delays,
    parsedLines,
  },
});

export const parseProgression = () => async (dispatch, getState) => {
  const { progressionSettings } = getState();
  if (!progressionSettings.progressionText) return null;
  dispatch(parseRequest({ progressionSettings }));
  try {
    // const {
    //   data: { data },
    // } = await axios({
    //   method: 'post',
    //   url: '/v1/progressions/parse',
    //   data: {
    //     arpeggiateStyle: progressionSettings.arpeggiateStyle,
    //     customArpeggio: progressionSettings.customArpeggio,
    //     rhythm:
    //       progressionSettings.rhythm && parseRhythm(progressionSettings.rhythm),
    //     bpm: progressionSettings.bpm,
    //     octave: progressionSettings.octave,
    //     progression: progressionSettings.progressionText,
    //   },
    // });
    const data = parser.parse({
      arpeggiateStyle: progressionSettings.arpeggiateStyle,
      customArpeggio: progressionSettings.customArpeggio,
      rhythm:
        progressionSettings.rhythm && parseRhythm(progressionSettings.rhythm),
      bpm: progressionSettings.bpm ?? 60,
      octave: progressionSettings.octave,
      progression: progressionSettings.progressionText,
    });
    return dispatch(
      parseSuccess({
        parsedProgression: data.progression,
        delays: data.delays,
        parsedLines: data.lines,
      }),
    );
  } catch (errorResponse) {
    return dispatch(
      parseFailure({
        error: parser.getErrrorMessage(errorResponse),
        detail: errorResponse,
      }),
    );
  }
};

const transposeRequest = ({ progression }) => ({
  type: types.TRANSPOSE_REQUEST,
  payload: {
    progression,
  },
});

const transposeFailure = ({ error, detail }) => ({
  type: types.TRANSPOSE_FAILURE,
  error,
  payload: {
    detail,
  },
});

const transposeSuccess = ({ transposedProgression, transpose }) => ({
  type: types.TRANSPOSE_SUCCESS,
  payload: {
    transposedProgression,
    transpose,
  },
});

export const transposeProgression = ({
  progressionText,
  transpose,
}) => async dispatch => {
  if (!progressionText) return null;
  dispatch(transposeRequest({ progression: progressionText }));
  try {
    // const {
    //   data: {
    //     data: { transposedProgression },
    //   },
    // } = await axios({
    //   method: 'post',
    //   url: '/v1/progressions/transpose',
    //   data: {
    //     progression: progressionText,
    //     transpose,
    //   },
    // });
    const { transposedProgression } = parser.transpose({
      progression: progressionText,
      transpose,
    });
    return dispatch(transposeSuccess({ transposedProgression, transpose }));
  } catch (errorResponse) {
    return dispatch(
      transposeFailure({
        error: helpers.errorMessage(errorResponse),
        detail: errorResponse,
      }),
    );
  }
};
