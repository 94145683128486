import UniversalRouter from 'universal-router';
// import * as appTypes from 'types/app';
import routes from './routes';

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    const state = context.store.getState();
    const {
      user: { authenticated },
    } = state;
    // redirect to login for protected routes
    if (context.route.protected && !authenticated) {
      return { redirect: '/login', from: context.pathname };
    }
    // if user is already logged in don't open /login and /register page
    if (context.route.redirectOnAuthenticated && authenticated) {
      return { redirect: '/', from: context.pathname };
    }
    if (typeof context.route.load === 'function') {
      return context.route
        .load()
        .then(action => action.default(context, params));
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return undefined;
  },
});
