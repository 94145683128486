import axios from 'axios';
import * as types from 'types/progression';
import initialState from '../store/initial-state';
import * as helpers from './helpers';
import { getProgressionSettings } from './progression-settings';

export function newProgression() {
  return {
    type: types.NEW_PROGRESSION,
    payload: {
      ...initialState.progression,
    },
  };
}

export function resetCurrentProgression() {
  return {
    type: types.RESET_CURRENT_PROGRESSION,
  };
}

export function setProgressionSlug(slug) {
  return {
    type: types.SET_PROGRESSION_SLUG,
    payload: {
      slug,
    },
  };
}

/**
 * Create Progression Action Creators
 */

const createRequest = () => ({
  type: types.CREATE_REQUEST,
});

const createSuccess = ({ progression }) => ({
  type: types.CREATE_SUCCESS,
  payload: {
    progression,
  },
});

export const create = ({ progressionSettings }) => async dispatch => {
  if (!progressionSettings.progressionText) {
    throw new Error('progressionText is required');
  }
  dispatch(createRequest());
  try {
    const url = '/v1/progressions';
    const method = 'post';

    const reqBody = {
      progressionText: progressionSettings.progressionText,
      // TODO originalProgressionText for transposed state
      originalProgressionText: progressionSettings.progressionText,
      settings: getProgressionSettings(progressionSettings),
    };

    const {
      data: {
        data: { progression: createdProgression },
      },
    } = await axios({
      method,
      url,
      data: reqBody,
    });
    return dispatch(createSuccess({ progression: createdProgression }));
  } catch (errorResponse) {
    return dispatch(helpers.errorAction(types.CREATE_FAILURE, errorResponse));
  }
};

/**
 * Update Progression Action Creators
 */

const updateRequest = () => ({
  type: types.UPDATE_REQUEST,
});

const updateSuccess = ({ progression }) => ({
  type: types.UPDATE_SUCCESS,
  payload: {
    progression,
  },
});

export const update = ({ slug, progressionSettings }) => async dispatch => {
  if (!progressionSettings.progressionText) {
    throw new Error('progressionText is required');
  }
  dispatch(updateRequest());
  try {
    const url = `/v1/progressions/${slug}`;
    const method = 'put';
    const reqBody = {
      progressionText: progressionSettings.progressionText,
      // TODO originalProgressionText for transposed state
      originalProgressionText: progressionSettings.progressionText,
      settings: getProgressionSettings(progressionSettings),
    };
    const {
      data: {
        data: { progression: updatedProgression },
      },
    } = await axios({
      method,
      url,
      data: reqBody,
    });
    return dispatch(updateSuccess({ progression: updatedProgression }));
  } catch (errorResponse) {
    return dispatch(helpers.errorAction(types.UPDATE_FAILURE, errorResponse));
  }
};

/**
 * Clone Progression Action Creators
 */
const cloneRequest = () => ({
  type: types.CLONE_REQUEST,
});

const cloneSuccess = ({ progression }) => ({
  type: types.CLONE_SUCCESS,
  payload: {
    progression,
  },
});

export const clone = progressionSlug => async dispatch => {
  dispatch(cloneRequest());
  try {
    const url = `/v1/progressions/${progressionSlug}/clone`;
    const method = 'post';
    const {
      data: {
        data: { progression: clonedProgression },
      },
    } = await axios({
      method,
      url,
    });
    return dispatch(cloneSuccess({ progression: clonedProgression }));
  } catch (errorResponse) {
    return dispatch(helpers.errorAction(types.CLONE_FAILURE, errorResponse));
  }
};

const fetchRequest = (progressionId, query) => ({
  type: types.FETCH_REQUEST,
  payload: {
    progressionId,
    query,
  },
});

const fetchFailure = ({ error, responseStatus }) => ({
  type: types.FETCH_FAILURE,
  error,
  payload: {
    responseStatus,
  },
});

const fetchSuccess = ({ progression, parsed, prev, next }) => ({
  type: types.FETCH_SUCCESS,
  payload: {
    progression,
    parsed,
    prev,
    next,
  },
});

export const fetch = (progressionId, query) => async dispatch => {
  if (!progressionId) {
    throw new Error('progressionId is required');
  }
  dispatch(fetchRequest(progressionId, query));
  try {
    const {
      data: {
        data: { progression, parsed, prev, next },
      },
    } = await axios({
      method: 'get',
      url: `/v1/progressions/${progressionId}`,
      params: query,
    });
    return dispatch(fetchSuccess({ progression, parsed, prev, next }));
  } catch (errorResponse) {
    return dispatch(
      fetchFailure({
        error: helpers.errorMessage(errorResponse),
        responseStatus: helpers.getResponseStatus(errorResponse),
      }),
    );
  }
};

/**
 * List/Search
 */
const searchRequest = ({ append }) => ({
  type: types.SEARCH_REQUEST,
  payload: {
    append,
  },
});

const searchFailure = ({ error, append }) => ({
  type: types.SEARCH_FAILURE,
  payload: {
    append,
  },
  error,
});

const searchSuccess = ({
  progressions,
  parsed,
  append,
  pagination,
  links,
}) => ({
  type: types.SEARCH_SUCCESS,
  payload: {
    append,
    progressions,
    parsed,
    pagination,
    links,
  },
});

export const search = ({ query, append = false }) => async dispatch => {
  dispatch(searchRequest({ append }));
  try {
    const {
      data: { data, meta, parsed, links },
    } = await axios.get(`/v1/progressions`, {
      params: query,
    });
    return dispatch(
      searchSuccess({
        progressions: data,
        parsed,
        pagination: meta,
        append,
        links,
      }),
    );
  } catch (errorResponse) {
    return dispatch(
      searchFailure({
        error: helpers.errorMessage(errorResponse),
        append,
      }),
    );
  }
};

/**
 * List/Search
 */
const searchUserProgressionRequest = ({ append }) => ({
  type: types.USER_PROGRESSION_REQUEST,
  payload: {
    append,
  },
});

const searchUserProgressionFailure = ({ error, append }) => ({
  type: types.USER_PROGRESSION_FAILURE,
  payload: {
    append,
  },
  error,
});

const searchUserProgressionSuccess = ({
  progressions,
  append,
  pagination,
  links,
}) => ({
  type: types.USER_PROGRESSION_SUCCESS,
  payload: {
    append,
    progressions,
    pagination,
    links,
  },
});

export const searchUserProgression = ({
  query,
  append = false,
}) => async dispatch => {
  if (!query.user) {
    throw new Error('query.user is required for action.searchUserProgression');
  }
  dispatch(searchUserProgressionRequest({ append }));
  try {
    const {
      data: { data, meta, links },
    } = await axios.get(`/v1/progressions`, {
      params: query,
    });
    return dispatch(
      searchUserProgressionSuccess({
        progressions: data,
        pagination: meta,
        append,
        links,
      }),
    );
  } catch (errorResponse) {
    return dispatch(
      searchUserProgressionFailure({
        error: helpers.errorMessage(errorResponse),
        append,
      }),
    );
  }
};
