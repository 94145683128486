import axios from 'axios';
import * as types from 'types/user';
import * as helpers from './helpers';

const loginRequest = () => ({
  type: types.LOGIN_REQUEST,
});

const loginFailure = ({ error }) => ({
  type: types.LOGIN_FAILURE,
  error,
});

const loginSuccess = ({ user, accessToken }) => ({
  type: types.LOGIN_SUCCESS,
  payload: {
    user,
    accessToken,
  },
});

export const login = ({ email, password }) => async dispatch => {
  dispatch(loginRequest());
  try {
    /* eslint camelcase: 'off' */
    const {
      data: {
        data: { accessToken, user },
      },
    } = await axios.post('/v1/auth/login', {
      email,
      password,
    });
    return dispatch(loginSuccess({ user, accessToken }));
  } catch (errorResponse) {
    return dispatch(
      loginFailure({ error: helpers.errorMessage(errorResponse) }),
    );
  }
};
const registerRequest = () => ({
  type: types.REGISTER_REQUEST,
});

const registerFailure = ({ error }) => ({
  type: types.REGISTER_FAILURE,
  error,
});

const registerSuccess = ({ user, accessToken }) => ({
  type: types.REGISTER_SUCCESS,
  payload: {
    user,
    accessToken,
  },
});

export const register = ({
  email,
  username,
  name,
  password,
}) => async dispatch => {
  dispatch(registerRequest());
  try {
    /* eslint camelcase: 'off' */
    const {
      data: {
        data: { accessToken, user },
      },
    } = await axios.post('/v1/auth/register', {
      email,
      username,
      name,
      password,
    });
    return dispatch(registerSuccess({ user, accessToken }));
  } catch (errorResponse) {
    return dispatch(
      registerFailure({ error: helpers.errorMessage(errorResponse) }),
    );
  }
};

const logoutRequest = () => ({
  type: types.LOGOUT_REQUEST,
});

const logoutFailure = ({ error }) => ({
  type: types.LOGOUT_FAILURE,
  error,
});

const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});

export const logout = () => async dispatch => {
  dispatch(logoutRequest());
  try {
    await axios.post('/v1/auth/logout');
    return dispatch(logoutSuccess());
  } catch (errorResponse) {
    return dispatch(
      logoutFailure({ error: helpers.errorMessage(errorResponse) }),
    );
  }
};

const currentUserRequest = () => ({
  type: types.CURRENT_USER_REQUEST,
});

const currentUserFailure = ({ error }) => ({
  type: types.CURRENT_USER_FAILURE,
  error,
});

const currentUserSuccess = ({ user }) => ({
  type: types.CURRENT_USER_SUCCESS,
  payload: {
    user,
  },
});

export const fetchCurrentUser = () => async dispatch => {
  dispatch(currentUserRequest());
  try {
    const {
      data: { data },
    } = await axios.get('/v1/users/me');
    return dispatch(currentUserSuccess({ user: data }));
  } catch (errorResponse) {
    return dispatch(
      currentUserFailure({
        error: helpers.errorMessage(errorResponse),
      }),
    );
  }
};
// Forgot Password
const forgotPasswordRequest = () => ({
  type: types.FORGOT_PASSWORD_REQUEST,
});

const forgotPasswordSuccess = ({ email }) => ({
  type: types.FORGOT_PASSWORD_SUCCESS,
  payload: {
    email,
  },
});

export const forgotPassword = ({ email }) => async dispatch => {
  dispatch(forgotPasswordRequest());
  try {
    await axios.post('/v1/users/forgot-password', { email });
    return dispatch(forgotPasswordSuccess({ email }));
  } catch (errorResponse) {
    return dispatch(
      helpers.errorAction(types.FORGOT_PASSWORD_FAILURE, errorResponse),
    );
  }
};

// Reset password
const resetPasswordRequest = () => ({
  type: types.RESET_PASSWORD_REQUEST,
});

const resetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_SUCCESS,
});

export const resetPassword = ({ password, token }) => async dispatch => {
  dispatch(resetPasswordRequest());
  try {
    await axios.post(`/v1/users/reset-password/${token}`, { password });
    return dispatch(resetPasswordSuccess());
  } catch (errorResponse) {
    return dispatch(
      helpers.errorAction(types.RESET_PASSWORD_FAILURE, errorResponse),
    );
  }
};

const fetchRequest = slug => ({
  type: types.FETCH_ENTITY_REQUEST,
  payload: {
    slug,
  },
});

const fetchFailure = ({ error, responseStatus }) => ({
  type: types.FETCH_ENTITY_FAILURE,
  error,
  payload: {
    responseStatus,
  },
});

const fetchSuccess = ({ progression, user, entityType }) => ({
  type: types.FETCH_ENTITY_SUCCESS,
  payload: {
    progression,
    user,
    entityType,
  },
});

export const fetchEntityBySlug = slug => async dispatch => {
  if (!slug) {
    throw new Error('slug is required');
  }
  dispatch(fetchRequest(slug));
  try {
    const {
      data: {
        data: { progression, user, entityType },
      },
    } = await axios({
      method: 'get',
      url: `/v1/slugs/${slug}`,
    });
    return dispatch(fetchSuccess({ progression, user, entityType }));
  } catch (errorResponse) {
    return dispatch(
      fetchFailure({
        error: helpers.errorMessage(errorResponse),
        responseStatus: helpers.getResponseStatus(errorResponse),
      }),
    );
  }
};
