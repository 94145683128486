import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { fetchCurrentUser } from 'actions/user';
import { setAccessTokenHeader } from 'utils';

import history from 'utils/history';

export default function AuthChecker({ children, redirectOnAuthenticated }) {
  const dispatch = useDispatch();
  const { authenticated } = useSelector(state => state.user);
  const { accessToken } = useSelector(state => state.app);

  useEffect(() => {
    setAccessTokenHeader(accessToken);

    if (accessToken && !authenticated) {
      dispatch(fetchCurrentUser());
    }
  }, [accessToken]);

  useEffect(() => {
    if (authenticated && redirectOnAuthenticated) {
      history.replace('/');
    }
  }, [authenticated]);

  return children;
}

AuthChecker.propTypes = {
  children: PropTypes.node.isRequired,
  redirectOnAuthenticated: PropTypes.bool,
};
AuthChecker.defaultProps = {
  redirectOnAuthenticated: false,
};
