export const CREATE_REQUEST = '@progression/create-request';
export const CREATE_SUCCESS = '@progression/create-success';
export const CREATE_FAILURE = '@progression/create-failure';

export const UPDATE_REQUEST = '@progression/update-request';
export const UPDATE_SUCCESS = '@progression/update-success';
export const UPDATE_FAILURE = '@progression/update-failure';

export const CLONE_REQUEST = '@progression/clone-request';
export const CLONE_SUCCESS = '@progression/clone-success';
export const CLONE_FAILURE = '@progression/clone-failure';

export const DELETE_REQUEST = '@progression/delete-request';
export const DELETE_SUCCESS = '@progression/delete-success';
export const DELETE_FAILURE = '@progression/delete-failure';

export const FETCH_REQUEST = '@progression/fetch-request';
export const FETCH_SUCCESS = '@progression/fetch-success';
export const FETCH_FAILURE = '@progression/fetch-failure';

export const SEARCH_REQUEST = '@progression/search-request';
export const SEARCH_SUCCESS = '@progression/search-success';
export const SEARCH_FAILURE = '@progression/search-failure';

export const USER_PROGRESSION_REQUEST = '@progression/user-progression-request';
export const USER_PROGRESSION_SUCCESS = '@progression/user-progression-success';
export const USER_PROGRESSION_FAILURE = '@progression/user-progression-failure';

export const NEW_PROGRESSION = '@progression/new';
export const SET_PROGRESSION_SLUG = '@progression/set-slug';
export const RESET_CURRENT_PROGRESSION =
  '@progression/reset-current-progression';
