import * as types from 'types/progression';
import * as appTypes from 'types/app';
import initialState from 'store/initial-state';
import { generateDummyData, filterLoadingFromList } from './helpers';

export default function progressionReducer(
  state = initialState.progression,
  action,
) {
  switch (action.type) {
    case types.NEW_PROGRESSION:
      return {
        ...action.payload,
      };
    case types.SET_PROGRESSION_SLUG:
      return {
        ...state,
        progressionSlug: action.payload.slug,
      };
    case types.RESET_CURRENT_PROGRESSION:
      return {
        ...state,
        currentProgression: null,
        fetchStatus: null,
        prev: null,
        next: null,
        fetchError: null,
        fetchResponseStatus: null,
      };
    // create
    case types.CREATE_REQUEST:
      return {
        ...state,
        createStatus: appTypes.STATUS_PENDING,
      };
    case types.CREATE_FAILURE:
      return {
        ...state,
        createStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.CREATE_SUCCESS:
      return {
        ...state,
        currentProgression: action.payload.progression,
        createStatus: appTypes.STATUS_SUCCESS,
        error: '',
      };
    // update
    case types.UPDATE_REQUEST:
      return {
        ...state,
        updateStatus: appTypes.STATUS_PENDING,
      };
    case types.UPDATE_FAILURE:
      return {
        ...state,
        updateStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        currentProgression: action.payload.progression,
        updateStatus: appTypes.STATUS_SUCCESS,
        error: '',
      };
    // clone
    case types.CLONE_REQUEST:
      return {
        ...state,
        cloneStatus: appTypes.STATUS_PENDING,
      };
    case types.CLONE_FAILURE:
      return {
        ...state,
        cloneStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.CLONE_SUCCESS:
      return {
        ...state,
        currentProgression: action.payload.progression,
        cloneStatus: appTypes.STATUS_SUCCESS,
        error: '',
      };

    // read
    case types.FETCH_REQUEST:
      return {
        ...state,
        currentProgression: generateDummyData(1)[0],
        fetchStatus: appTypes.STATUS_PENDING,
        fetchError: '',
        fetchResponseStatus: null,
      };
    case types.FETCH_FAILURE:
      return {
        ...state,
        fetchStatus: appTypes.STATUS_FAILURE,
        currentProgression: null,
        prev: null,
        next: null,
        fetchError: action.error,
        fetchResponseStatus: action.payload.responseStatus,
      };
    case types.FETCH_SUCCESS:
      return {
        ...state,
        currentProgression: action.payload.progression,
        parsed: action.payload.parsed,
        prev: action.payload.prev,
        next: action.payload.next,
        progressionText: action.payload.progression.progressionText,
        fetchStatus: appTypes.STATUS_SUCCESS,
      };

    // delete
    case types.DELETE_REQUEST:
      return {
        ...state,
        deleteStatus: appTypes.STATUS_PENDING,
      };
    case types.DELETE_FAILURE:
      return {
        ...state,
        deleteStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.DELETE_SUCCESS:
      return {
        ...state,
        deleteStatus: appTypes.STATUS_SUCCESS,
        progressions: state.progressions.filter(
          progression => progression.slug !== action.slug,
        ),
        error: '',
      };

    // search
    case types.SEARCH_REQUEST:
      return {
        ...state,
        error: '',
        progressions: state.progressions.concat(generateDummyData(4)),
        searchStatus: appTypes.STATUS_PENDING,
      };
    case types.SEARCH_FAILURE:
      return {
        ...state,
        searchStatus: appTypes.STATUS_FAILURE,
        progressions: filterLoadingFromList(state.progressions),
        error: action.error,
      };
    case types.SEARCH_SUCCESS:
      return {
        ...state,
        pagination: action.payload.pagination,
        progressions: filterLoadingFromList(
          action.payload.append
            ? state.progressions.concat(action.payload.progressions)
            : action.payload.progressions,
        ),
        searchStatus: appTypes.STATUS_SUCCESS,
        error: '',
      };
    // search
    case types.USER_PROGRESSION_REQUEST:
      return {
        ...state,
        error: '',
        userProgressions: state.userProgressions.concat(generateDummyData(4)),
        userProgressionSearchStatus: appTypes.STATUS_PENDING,
      };
    case types.USER_PROGRESSION_FAILURE:
      return {
        ...state,
        userProgressionSearchStatus: appTypes.STATUS_FAILURE,
        userProgressions: filterLoadingFromList(state.userProgressions),
        error: action.error,
      };
    case types.USER_PROGRESSION_SUCCESS:
      return {
        ...state,
        userProgressionPagination: action.payload.pagination,
        userProgressions: filterLoadingFromList(
          action.payload.append
            ? state.userProgressions.concat(action.payload.progressions)
            : action.payload.progressions,
        ),
        userProgressionSearchStatus: appTypes.STATUS_SUCCESS,
        error: '',
      };
    default:
      return state;
  }
}
