import * as types from 'types/player';
import initialState from 'store/initial-state';

export default function playerReducer(state = initialState.player, action) {
  switch (action.type) {
    case types.CLEAR_PLAYER:
      return {
        ...state,
        currentTime: 0,
        totalChords: 0,
        isPlaying: false,
        // shows chord progress
        progress: 0,
        markerPosition: 0,
        scrollLeft: 1,
        prevIndex: -1,
        completed: false,
        loopCount: 1,
        sampler: null,
      };
    case types.SYNC_CHANGES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
