import * as progressionModule from '@composer-studio/composer/dist/progression';
import * as rhythmModule from '@composer-studio/composer/dist/rhythm';
import * as helpers from './helpers';

export function getErrrorMessage(error) {
  return error.message;
}

export function parse(body) {
  const {
    progression,
    arpeggiateStyle,
    customArpeggio,
    octave,
    bpm,
    rhythm,
  } = body;
  const progressionArray = progressionModule.parse(progression);

  const builtProgression = helpers.buildProgression({
    progressionArray,
    arpeggiateStyle,
    octave,
    customArpeggio,
  });

  const result = {
    progression: builtProgression,
    lines: helpers.getChordsAndLyrics(progression),
  };

  if (rhythm && bpm) {
    const dealysInMs = rhythmModule.build(rhythm, bpm);
    result.delays = dealysInMs;
  }

  return result;
}

export function transpose(body) {
  const { progression, transpose: transposeNum } = body;
  const transposedProgression = progressionModule.transpose({
    progression,
    num: Number(transposeNum),
  });

  const result = {
    transposedProgression,
  };

  return result;
}

export function delaysFromRhythm(body) {
  const { bpm, rhythm } = body;
  const dealysInMs = rhythmModule.build(rhythm, bpm);
  const result = {
    delays: dealysInMs,
  };
  return result;
}
