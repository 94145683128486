import * as types from 'types/app';
import axios from 'axios';

export const setLoading = isLoading => ({
  type: types.SET_LOADING,
  payload: {
    isLoading,
  },
});

export const setFullScreen = isFullScreen => ({
  type: types.SET_FULL_SCREEN,
  payload: {
    isFullScreen,
  },
});

export const setMessage = message => ({
  type: types.SET_MESSAGE,
  payload: {
    message,
  },
});

export const routeChange = location => ({
  type: types.ROUTE_CHANGE,
  payload: {
    location,
  },
});

export const setLang = lang => ({
  type: types.SET_LANG,
  payload: {
    lang,
  },
});

export const setTheme = theme => ({
  type: types.SET_THEME,
  payload: {
    theme,
  },
});

export const setAccessToken = accessToken => ({
  type: types.SET_ACCESS_TOKEN,
  payload: {
    accessToken,
  },
});

export const setError = error => ({
  type: types.SET_ERROR,
  error,
});

export async function postBrowserError(errorBody) {
  if (process.env.isDev) {
    console.error(errorBody.error);
    console.error(errorBody.stack);
    console.error(errorBody.source);
    console.error(errorBody.url);
    return console.error(errorBody.options);
  }

  const {
    data: { data },
  } = await axios.post('/v1/error/browser', errorBody);
  return data;
}
