import * as types from 'types/progression-settings';
import { FETCH_SUCCESS, NEW_PROGRESSION } from 'types/progression';
import * as appTypes from 'types/app';
import initialState from 'store/initial-state';

export default function progressionSettingsReducer(
  state = initialState.progressionSettings,
  action,
) {
  switch (action.type) {
    case types.SYNC_CHANGES:
      return {
        ...state,
        ...action.payload,
      };
    case types.RESET_SETTINGS:
      return initialState.progressionSettings;
    case types.SET_INSTRUMENT_REQUEST:
      return {
        ...state,
        setInstrumentStatus: appTypes.STATUS_PENDING,
      };
    case types.SET_INSTRUMENT_FAILURE:
      return {
        ...state,
        setInstrumentStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.SET_INSTRUMENT_SUCCESS:
      return {
        ...state,
        setInstrumentStatus: appTypes.STATUS_SUCCESS,
        instrument: action.payload.instrument,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        progressionText: action.payload.progression.progressionText,
        parsedLines: action.payload.parsed.lines,
        originalProgressionText:
          action.payload.progression.originalProgressionText,
        ...action.payload.progression.settings,
      };
    case NEW_PROGRESSION:
      return initialState.progressionSettings;
    // parse
    case types.PARSE_REQUEST:
      return {
        ...state,
        parseStatus: appTypes.STATUS_PENDING,
      };
    case types.PARSE_FAILURE:
      return {
        ...state,
        parseStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.PARSE_SUCCESS:
      return {
        ...state,
        parsedProgression: action.payload.parsedProgression,
        delays: action.payload.delays,
        parsedLines: action.payload.parsedLines,
        parseStatus: appTypes.STATUS_SUCCESS,
        error: '',
      };

    // transpose
    case types.TRANSPOSE_REQUEST:
      return {
        ...state,
        transposeStatus: appTypes.STATUS_PENDING,
      };
    case types.TRANSPOSE_FAILURE:
      return {
        ...state,
        transposeStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.TRANSPOSE_SUCCESS:
      return {
        ...state,
        progressionText: action.payload.transposedProgression,
        transpose: action.payload.transpose,
        transposeStatus: appTypes.STATUS_SUCCESS,
        error: '',
      };

    default:
      return state;
  }
}
