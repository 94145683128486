import axios from 'axios';
import debounce from 'lodash/debounce';
import history from './history';

export function encodeURL(string) {
  return encodeURIComponent(string);
}

export function decodeURL(string) {
  return decodeURIComponent(string);
}

export function getJsonFromUrl(location) {
  const query = location && location.search.substr(1);
  if (!query) return '';
  const result = {};
  query.split('&').forEach(part => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export const routeToQueryRedirect = () => {
  const query = getJsonFromUrl(history.location);
  if (query && query.redirect) {
    return history.replace(query.redirect);
  }
  return history.replace('/');
};

export const modalRoutes = ['discover', 'user'];

export const isModalRoute = routeName => {
  return modalRoutes.find(modalRoute => modalRoute === routeName);
};

export const classNamesHelper = classNames => {
  return classNames.join(' ');
};

export const getStringElipsis = (string = '', len) => {
  if (string.length < len) return string;
  return `${string.substr(0, len)}...`;
};

export const usernameDisplayLength = username => {
  return getStringElipsis(username, 10);
};

export const getShortTitle = title => {
  return getStringElipsis(title, 50);
};

export const formatTime = seconds => {
  const format = val => `0${Math.floor(val)}`.slice(-2);
  const minutes = (seconds % 3600) / 60;
  // const milliseconds = seconds * 1000;
  const timeArray = [minutes, seconds % 60].map(format);
  // timeArray.push(Math.round(milliseconds % 1000, 2));
  return timeArray.join(':');
};

export const setAccessTokenHeader = accessToken => {
  axios.defaults.headers.common['x-access-token'] = accessToken;
};

export const debounceInput = (func, time) => {
  return debounce(func, time || 300);
};
