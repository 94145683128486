export const LOGIN_REQUEST = '@user/login-request';
export const LOGIN_SUCCESS = '@user/login-success';
export const LOGIN_FAILURE = '@user/login-failure';

export const LOGOUT_REQUEST = '@user/logout-request';
export const LOGOUT_SUCCESS = '@user/logout-success';
export const LOGOUT_FAILURE = '@user/logout-failure';

export const REGISTER_REQUEST = '@user/register-request';
export const REGISTER_SUCCESS = '@user/register-success';
export const REGISTER_FAILURE = '@user/register-failure';

export const CURRENT_USER_REQUEST = '@user/current-user-request';
export const CURRENT_USER_SUCCESS = '@user/current-user-success';
export const CURRENT_USER_FAILURE = '@user/current-user-failure';

export const FORGOT_PASSWORD_REQUEST = '@user/forgot-password-request';
export const FORGOT_PASSWORD_SUCCESS = '@user/forgot-password-success';
export const FORGOT_PASSWORD_FAILURE = '@user/forgot-password-failure';

export const RESET_PASSWORD_REQUEST = '@user/reset-password-request';
export const RESET_PASSWORD_SUCCESS = '@user/reset-password-success';
export const RESET_PASSWORD_FAILURE = '@user/reset-password-failure';

export const FETCH_ENTITY_REQUEST = '@app/fetch-entity-pending';
export const FETCH_ENTITY_FAILURE = '@app/fetch-entity-failure';
export const FETCH_ENTITY_SUCCESS = '@app/fetch-entity-success';

export const ENTITY_TYPE_USER = 'user';
// export const ENTITY_TYPE_ORG = 'org';

export const ROLES = {
  user: 'user',
  admin: 'admin',
};
