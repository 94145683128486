import composerTone from '@composer-studio/composer-tonejs';
import { generateDummyData } from 'reducers/helpers';
// import composerTone from '../../../composer-tonejs/dist/composer-tonejs';

const player = new composerTone.Player();

const THEMES = {
  default: '',
  dark: 'dark-mode',
};

const DEFAULT_INSTRUMENT = 'web-audio';

const initialState = {
  app: {
    theme: THEMES.default,
    isFullScreen: false,
    lang: 'en',
    error: '',
    message: '',
    accessToken: '',
  },
  player: {
    currentTime: 0,
    totalChords: 0,
    isPlaying: false,
    // shows chord progress
    progress: 0,
    markerPosition: 0,
    scrollLeft: 1,
    prevIndex: -1,
    completed: false,
    loopCount: 1,
    progressionPlayer: player,
    samplerConfig: null,
  },
  // multiPlayer: {},
  progressionSettings: {
    isArpeggio: false,
    arpeggiateStyle: '',
    customArpeggio: '',
    velocity: 'full',
    transpose: 0,
    time: 4,
    bpm: undefined,
    loop: false,
    octave: 3,
    ADSR: '',
    IADSR: false,
    instrument: DEFAULT_INSTRUMENT,
    timeSignature: '',
    rhythm: '',
    // useful for tranposing
    autoChanged: false,
    originalProgressionText: '',
    progressionText: '',
    // holds progression with octave
    parsedProgression: [],
    delays: null,
    settingsChanged: false,
    warnEmpty: false,
    setInstrumentStatus: '',
    parseStatus: '',
    transposeStatus: '',
  },
  progression: {
    // current progression loaded from db
    progressions: generateDummyData(4),
    currentProgression: null,
    userProgressions: generateDummyData(4),
    progressionSlug: '',
    pagination: null,
    prev: null,
    next: null,
  },
  user: {
    authenticated: false,
    user: null,
    entityProfile: null,
    currentUserStatus: null,
  },
  media: {
    mediua: {},
    mediaList: [],
  },
};

export default initialState;
