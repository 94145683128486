import { combineReducers } from 'redux';

import app from './app';
import player from './player';
// import multiPlayer from './multi-player';
import progression from './progression';
import progressionSettings from './progression-settings';
import user from './user';
import media from './media';

export default combineReducers({
  app,
  player,
  // multiPlayer,
  progression,
  progressionSettings,
  user,
  media,
});
