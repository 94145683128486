import * as types from 'types/media';
import * as appTypes from 'types/app';
import initialState from 'store/initial-state';
import { generateDummyData, filterLoadingFromList } from './helpers';

export default function progressionReducer(state = initialState.media, action) {
  switch (action.type) {
    // create
    case types.CREATE_REQUEST:
      return {
        ...state,
        createStatus: appTypes.STATUS_PENDING,
      };
    case types.CREATE_FAILURE:
      return {
        ...state,
        createStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.CREATE_SUCCESS:
      return {
        ...state,
        media: action.payload.media,
        createStatus: appTypes.STATUS_SUCCESS,
        error: '',
      };
    // update
    case types.UPDATE_REQUEST:
      return {
        ...state,
        updateStatus: appTypes.STATUS_PENDING,
      };
    case types.UPDATE_FAILURE:
      return {
        ...state,
        updateStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        media: action.payload.media,
        updateStatus: appTypes.STATUS_SUCCESS,
        error: '',
      };

    // delete
    case types.DELETE_REQUEST:
      return {
        ...state,
        deleteStatus: appTypes.STATUS_PENDING,
      };
    case types.DELETE_FAILURE:
      return {
        ...state,
        deleteStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.DELETE_SUCCESS:
      return {
        ...state,
        deleteStatus: appTypes.STATUS_SUCCESS,
        mediaList: state.mediaList.filter(media => media._id !== action.id),
        error: '',
      };

    // search
    case types.SEARCH_REQUEST:
      return {
        ...state,
        error: '',
        mediaList: state.mediaList.concat(generateDummyData(4)),
        searchStatus: appTypes.STATUS_PENDING,
      };
    case types.SEARCH_FAILURE:
      return {
        ...state,
        searchStatus: appTypes.STATUS_FAILURE,
        mediaList: filterLoadingFromList(state.mediaList),
        error: action.error,
      };
    case types.SEARCH_SUCCESS:
      return {
        ...state,
        pagination: action.payload.pagination,
        mediaList: filterLoadingFromList(
          action.payload.append
            ? state.mediaList.concat(action.payload.mediaList)
            : action.payload.mediaList,
        ),
        searchStatus: appTypes.STATUS_SUCCESS,
        error: '',
      };
    default:
      return state;
  }
}
