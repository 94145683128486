import * as arpeggioModule from '@composer-studio/composer/dist/arpeggio';
import * as octaveHelpers from '@composer-studio/composer/dist/octave';
import * as progressionModule from '@composer-studio/composer/dist/progression';

/* Get lines helper */
export function getChordsAndLyrics(progressionString) {
  return progressionModule.getChordsAndLyrics(progressionString);
}
/**
 * Progression Style Helper
 */
export function getProgressionStyle({
  progressionArray,
  arpeggiateStyle,
  octave,
}) {
  return progressionArray.map(chordObject => {
    if (chordObject.rest) {
      return chordObject;
    }
    const arpeggioStyle = arpeggioModule[arpeggiateStyle]({
      chord: chordObject.chord,
      octave: chordObject.octave || octave,
      objectResult: true,
    });
    return { ...chordObject, chord: arpeggioStyle };
  });
}

/**
 * Custom Arpeggio Formula Helper
 */
export function getCustomArpeggioFormula(customArpeggioText) {
  return customArpeggioText
    .split(',')
    .map(num => num.trim())
    .filter(num => num);
}

/**
 * Custom Arpeggio Helper
 */
export function buildCustomArpeggio(progressionArray, customArpeggio, octave) {
  return progressionArray.map(chordObject => {
    if (chordObject.rest) {
      return chordObject;
    }
    const formula = getCustomArpeggioFormula(customArpeggio);

    const arpeggioStyle = arpeggioModule.buildFromFormula({
      note: chordObject.symbol,
      octave: chordObject.octave || octave,
      formula,
      objectResult: true,
    });
    return { ...chordObject, chord: arpeggioStyle };
  });
}

/**
 * Progression Build Helper
 */
export function buildProgression({
  progressionArray,
  arpeggiateStyle,
  customArpeggio,
  octave = 3,
}) {
  if (customArpeggio) {
    return buildCustomArpeggio(progressionArray, customArpeggio, octave);
  }

  if (
    arpeggiateStyle &&
    arpeggiateStyle !== 'default' &&
    arpeggiateStyle !== 'default-reverse'
  ) {
    return getProgressionStyle({ progressionArray, arpeggiateStyle, octave });
  }

  let progression = octaveHelpers.appendOctaveProgression({
    progression: progressionArray,
    octave,
    objectResult: true,
  });

  if (arpeggiateStyle === 'default-reverse') {
    progression = progression.map(chordObject => ({
      ...chordObject,
      chord: chordObject.chord.reverse(),
    }));
  }
  return progression;
}
