import { BASE_ROUTE_PROGRESSION, BASE_ROUTE_DISCOVER } from 'types/app';

const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: BASE_ROUTE_PROGRESSION,
      children: [
        {
          path: '/:progressionSlug',
          load: () =>
            import(
              /* webpackChunkName: 'view-progression' */ './view-progression'
            ),
        },
        {
          path: '/:progressionSlug/edit',
          load: () =>
            import(
              /* webpackChunkName: 'edit-progression' */ './edit-progression'
            ),
        },
      ],
    },
    {
      path: BASE_ROUTE_DISCOVER,
      children: [
        {
          path: '',
          load: () =>
            import(/* webpackChunkName: 'progressions' */ './progressions'),
        },
      ],
    },
    // {
    //   path: '/contact',
    //   load: () => import(/* webpackChunkName: 'contact' */ './contact'),
    // },
    {
      path: '/login',
      // redirect to home if logged in
      redirectOnAuthenticated: true,
      load: () => import(/* webpackChunkName: 'login' */ './login'),
    },
    {
      path: '/register',
      redirectOnAuthenticated: true,
      load: () => import(/* webpackChunkName: 'register' */ './register'),
    },
    {
      path: '/forgot-password',
      redirectOnAuthenticated: true,
      load: () =>
        import(/* webpackChunkName: 'forgot-password' */ './forgot-password'),
    },
    {
      path: '/reset-password/:resetPasswordToken',
      redirectOnAuthenticated: true,
      load: () =>
        import(/* webpackChunkName: 'reset-password' */ './reset-password'),
    },
    // {
    //   path: '/about',
    //   load: () => import(/* webpackChunkName: 'about' */ './about'),
    // },
    // {
    //   path: '/privacy',
    //   load: () => import(/* webpackChunkName: 'privacy' */ './privacy'),
    // },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '/:slug',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'user' */ './user'),
        },
        {
          path: '/midi',
          load: () => import(/* webpackChunkName: 'user-midi' */ './midi'),
        },
      ],
    },
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],
  async action({ next, pathname, params }) {
    // Execute each child route until one of them return the result
    const route = await next();
    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} - composer.studio`;
    route.pathname = route.pathname || pathname;
    route.params = route.params || params;
    route.routeName = route.routeName;
    // route.
    route.description =
      route.description ||
      'A tool for composers where they can compose musical phrases online and share it with others';
    route.keywords =
      route.keywords ||
      'composer,studio,music,arpeggio,chords,progression,transpose,ADSR,rhythm,time signature';
    route.themeColor = route.themeColor || '#fff';
    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: import('./error').default,
  });

  routes.children.unshift({
    path: '/design-system',
    load: () =>
      import(/* webpackChunkName: 'design-system' */ './design-system'),
  });
}

export default routes;
