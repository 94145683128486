export const STATUS_PENDING = 'pending';
export const STATUS_FAILURE = 'failure';
export const STATUS_SUCCESS = 'success';

export const ACCESS_TOKEN_KEY = 'composer-access-token';
export const SET_ACCESS_TOKEN = '@app/set-access-token';

export const ROUTE_CHANGE = '@app/route-change';
export const SET_LOADING = '@app/set-loading';
export const SET_FULL_SCREEN = '@app/set-full-screen';

export const SET_LANG = '@app/set-lang';
export const SET_THEME = '@app/set-theme';

export const SET_ERROR = '@app/set-error';
export const SET_MESSAGE = '@app/set-message';

export const BASE_ROUTE_PROGRESSION = '/p';
export const BASE_ROUTE_DISCOVER = '/discover';
