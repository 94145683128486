import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from 'reducers';
import { persistReducer } from 'redux-persist';
import persistStorage from 'redux-persist/lib/storage';
import { name, version } from '../../package.json';
import createLogger from './logger';

export default function configureStore(initialState, helpersConfig) {
  const middleware = [thunk.withExtraArgument(helpersConfig)];

  let enhancer;

  if (__DEV__) {
    middleware.push(createLogger());

    // https://github.com/zalmoxisus/redux-devtools-extension#14-using-in-production
    const composeEnhancers = composeWithDevTools({
      // Options: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#options
      name: `${name}@${version}`,
    });

    // https://redux.js.org/docs/api/applyMiddleware.html
    enhancer = composeEnhancers(applyMiddleware(...middleware));
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  const persistConfig = {
    // change key version when default state changes
    key: 'composer:v1.4',
    storage: persistStorage,
    // state to persist
    whitelist: __DEV__ ? ['app', 'progressionSettings'] : ['app'],
    // There is an issue in the source code of redux-persist (default setTimeout does not cleaning)
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  // https://redux.js.org/docs/api/createStore.html
  const store = createStore(persistedReducer, initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept('../reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('../reducers').default),
    );
  }

  return store;
}
