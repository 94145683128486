import * as types from 'types/app';
import initialState from 'store/initial-state';

export default function appReducer(state = initialState.app, action) {
  switch (action.type) {
    case types.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case types.SET_FULL_SCREEN:
      return {
        ...state,
        isFullScreen: action.payload.isFullScreen,
      };
    case types.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case types.SET_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
      };
    case types.SET_LANG:
      return {
        ...state,
        lang: action.payload.lang,
      };
    case types.SET_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };
    case types.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };
    default:
      return state;
  }
}
