/* eslint import/prefer-default-export: 'off' */
const getUniqueDummy = () => {
  const id = `unique-id-${Math.random()}`;
  return { _id: id, loading: true };
};

export function generateDummyData(n) {
  return Array.from({ length: n }).map(getUniqueDummy);
}

export function filterLoadingFromList(list) {
  return list.filter(item => !item.loading);
}
