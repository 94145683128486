export const PARSE_REQUEST = '@progression-settings/parse-request';
export const PARSE_SUCCESS = '@progression-settings/parse-success';
export const PARSE_FAILURE = '@progression-settings/parse-failure';

export const TRANSPOSE_REQUEST = '@progression-settings/transpose-request';
export const TRANSPOSE_SUCCESS = '@progression-settings/transpose-success';
export const TRANSPOSE_FAILURE = '@progression-settings/transpose-failure';

export const SET_INSTRUMENT_REQUEST =
  '@progression-settings/set-instrument-request';
export const SET_INSTRUMENT_SUCCESS =
  '@progression-settings/set-instrument-success';
export const SET_INSTRUMENT_FAILURE =
  '@progression-settings/set-instrument-failure';

export const SYNC_CHANGES = '@progression-settings/sync-changes';
export const RESET_SETTINGS = '@progression-settings/reset-settings';
