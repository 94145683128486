import React from 'react';
import ReactDOM from 'react-dom';
import * as Tone from 'tone';
import deepForceUpdate from 'react-deep-force-update';
import queryString from 'query-string';
import { createPath } from 'history';
import nprogress from 'nprogress';
import axios from 'axios';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import AuthChecker from 'components/AuthChecker';
import App from 'components/App';

import { setLoading, setError, routeChange } from 'actions/app';
import { setProgressionSlug } from 'actions/progression';
import { setInstrumentFailure } from 'actions/progression-settings';

import history from 'utils/history';
import configureStore from './store/configure-store';
import initialState from './store/initial-state';
import { updateMeta } from './DOMUtils';
import router from './router';

// Enables critical path CSS rendering
// https://github.com/kriasoft/isomorphic-style-loader
const insertCss = (...styles) => {
  // eslint-disable-next-line no-underscore-dangle
  const removeCss = styles.map(x => x._insertCss());
  return () => {
    removeCss.forEach(f => f());
  };
};

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const store = configureStore(
  { ...window.App.state, player: initialState.player },
  { history },
);

axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    nprogress.start();
    return config;
  },
  error => {
    nprogress.done();
    // Do something with request error
    return Promise.reject(error);
  },
);

nprogress.configure({ showSpinner: false });
const axiosBaseUrl = window.App.apiUrl;
axios.defaults.baseURL = axiosBaseUrl;

axios.interceptors.response.use(
  config => {
    nprogress.done();
    return config;
  },
  error => {
    nprogress.done();
    return Promise.reject(error);
  },
);

// Tone.context.latencyHint = 'fastest';
// Tone.setContext(new Tone.Context({ latencyHint: "balanced" }))

Tone.loaded()
  .then(() => {
    nprogress.done();
  })
  .catch(error => {
    store.dispatch(
      setInstrumentFailure({
        error: 'Error Loading Samples',
        detail: error,
      }),
    );
    nprogress.done();
  });

const context = {
  store,
  storeSubscription: null,
};
const reduxPersistor = persistStore(store);

// Initialize a new Redux store
const container = document.getElementById('composer-app-root');
let currentLocation = history.location;
/*  eslint no-unused-vars: 'off' */
let appInstance;

const scrollPositionsHistory = {};

store.dispatch(setLoading(false));
store.dispatch(setError(''));
// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const isInitialRender = !action;
  try {
    store.dispatch(setLoading(true));
    store.dispatch(routeChange(location));
    context.pathname = location.pathname;
    context.query = queryString.parse(location.search);
    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve(context);
    store.dispatch(setLoading(false));

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }
    if (!isInitialRender && location.state?.progressionModalRoute) {
      store.dispatch(setProgressionSlug(route.params.progressionSlug));
      return;
    }
    if (!route?.params?.progressionSlug) {
      store.dispatch(setProgressionSlug(''));
    }
    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }
    const renderReactApp = isInitialRender ? ReactDOM.hydrate : ReactDOM.render;
    appInstance = renderReactApp(
      <App context={context} insertCss={insertCss}>
        <PersistGate loading={null} persistor={reduxPersistor}>
          <AuthChecker redirectOnAuthenticated={route.redirectOnAuthenticated}>
            {route.component}
          </AuthChecker>
        </PersistGate>
      </App>,
      container,
      () => {
        if (isInitialRender) {
          // Switch off the native scroll restoration behavior and handle it manually
          // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
          if (window.history && 'scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
          }

          const elem = document.getElementById('css');
          if (elem) elem.parentNode.removeChild(elem);
          return;
        }

        document.title = route.title;

        updateMeta('description', route.description);
        // Update necessary tags in <head> at runtime here, ie:
        // updateMeta('keywords', route.keywords);
        // updateCustomMeta('og:url', route.canonicalUrl);
        // updateCustomMeta('og:image', route.imageUrl);
        // updateLink('canonical', route.canonicalUrl);
        // etc.

        let scrollX = 0;
        let scrollY = 0;
        const pos = scrollPositionsHistory[location.key];
        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          const targetHash = location.hash.substr(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }
        // Restore the scroll position if it was saved into the state
        // or scroll to the given #hash anchor
        // or scroll to top of the page
        // window.scrollTo(scrollX, scrollY);

        // Google Analytics tracking. Don't send 'pageview' event after
        // the initial rendering, as it was already sent
        if (window.ga) {
          window.ga('send', 'pageview', createPath(location));
        }
      },
    );
  } catch (error) {
    if (__DEV__) {
      throw error;
    }
    console.error(error);
    // Do a full page reload if error occurs during client-side navigation
    if (!isInitialRender && currentLocation.key === location.key) {
      console.error('RSK will reload your page after error');
      window.location.reload();
    }
  }
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
// TODO handle modal as route
// when link is copied, render it in normal view
history.listen(onLocationChange);
onLocationChange(currentLocation);

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }

    onLocationChange(currentLocation);
  });
}
