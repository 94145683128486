import * as types from 'types/user';
import * as appTypes from 'types/app';
import initialState from 'store/initial-state';

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        authenticated: false,
        loginStatus: appTypes.STATUS_PENDING,
        error: '',
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        authenticated: false,
        loginStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        user: action.payload.user,
        loginStatus: appTypes.STATUS_SUCCESS,
      };
    case types.CURRENT_USER_REQUEST:
      return {
        ...state,
        authenticated: false,
        currentUserStatus: appTypes.STATUS_PENDING,
        error: '',
      };
    case types.CURRENT_USER_FAILURE:
      return {
        ...state,
        authenticated: false,
        currentUserStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.CURRENT_USER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        user: action.payload.user,
        currentUserStatus: appTypes.STATUS_SUCCESS,
      };
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        logoutStatus: appTypes.STATUS_PENDING,
        error: '',
      };
    case types.LOGOUT_FAILURE:
      return {
        ...state,
        logoutStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        authenticated: false,
        user: null,
        logoutStatus: appTypes.STATUS_SUCCESS,
      };
    case types.REGISTER_REQUEST:
      return {
        ...state,
        authenticated: false,
        registerStatus: appTypes.STATUS_PENDING,
        error: '',
      };
    case types.REGISTER_FAILURE:
      return {
        ...state,
        authenticated: false,
        registerStatus: appTypes.STATUS_FAILURE,
        error: action.error,
      };
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        user: action.payload.user,
        registerStatus: appTypes.STATUS_SUCCESS,
      };
    case types.FETCH_ENTITY_REQUEST:
      return {
        ...state,
        fetchEntityStatus: appTypes.STATUS_PENDING,
        fetchEntityError: '',
        responseStatus: null,
      };
    case types.FETCH_ENTITY_FAILURE:
      return {
        ...state,
        fetchEntityStatus: appTypes.STATUS_FAILURE,
        fetchEntityError: action.error,
        responseStatus: action.payload.responseStatus,
      };
    case types.FETCH_ENTITY_SUCCESS:
      return {
        ...state,
        entityType: action.payload.entityType,
        entityProfile: action.payload[action.payload.entityType],
        fetchEntityStatus: appTypes.STATUS_SUCCESS,
      };
    case appTypes.ROUTE_CHANGE:
      return {
        ...state,
        error: '',
        currentUserStatus: '',
      };
    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordStatus: appTypes.STATUS_PENDING,
        forgotPasswordError: '',
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordStatus: appTypes.STATUS_SUCCESS,
        forgotPasswordError: '',
        forgotPasswordMessage: `A password reset link has been sent to your email: ${action.payload.email}`,
      };
    case types.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        authenticated: false,
        forgotPasswordStatus: appTypes.STATUS_PENDING,
        forgotPasswordError: action.error,
        forgotPasswordMessage: '',
      };
    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordStatus: appTypes.STATUS_PENDING,
        resetPasswordError: '',
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordStatus: appTypes.STATUS_SUCCESS,
        resetPasswordError: '',
        resetPasswordMessage: `Password Reset Successfull. Please login to continue`,
      };
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        authenticated: false,
        resetPasswordStatus: appTypes.STATUS_PENDING,
        resetPasswordError: action.error,
        resetPasswordMessage: '',
      };
    default:
      return state;
  }
}
