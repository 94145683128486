export const CREATE_REQUEST = '@media/create-request';
export const CREATE_SUCCESS = '@media/create-success';
export const CREATE_FAILURE = '@media/create-failure';

export const UPDATE_REQUEST = '@media/update-request';
export const UPDATE_SUCCESS = '@media/update-success';
export const UPDATE_FAILURE = '@media/update-failure';

export const DELETE_REQUEST = '@media/delete-request';
export const DELETE_SUCCESS = '@media/delete-success';
export const DELETE_FAILURE = '@media/delete-failure';

export const SEARCH_REQUEST = '@media/search-request';
export const SEARCH_SUCCESS = '@media/search-success';
export const SEARCH_FAILURE = '@media/search-failure';
