export function errorMessage(errorResponse) {
  if (errorResponse.response) {
    let error =
      errorResponse.response &&
      errorResponse.response.data &&
      (errorResponse.response.data.error ||
        errorResponse.response.data.message);
    if (!error) {
      error = 'Something went wrong';
    }
    return error;
  }

  if (errorResponse.request) {
    return 'NETWORK_ERROR';
  }
  return errorResponse.message;
}

export function getResponseStatus(e) {
  return e.response && e.response.status;
}

export function successMessage(message) {
  return message;
}

export function validationMessages(e) {
  const validationErrors =
    (e.response && e.response.data && e.response.data.validationErrors) || {};

  const messages = Object.keys(validationErrors)
    .map(fieldName => {
      const validationObjects = Object.keys(validationErrors[fieldName]).map(
        validationType => ({
          code: `${fieldName}.${validationType}`,
          message: validationErrors[fieldName][validationType],
        }),
      );
      return validationObjects;
    })
    .reduce((prev, next) => prev.concat(next), []);
  return messages;
}

export function errorAction(actionType, errorResponse) {
  return {
    type: actionType,
    error: errorMessage(errorResponse),
    payload: {
      validationMessages: validationMessages(errorResponse),
      responseStatus: getResponseStatus(errorResponse),
    },
  };
}
