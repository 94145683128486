/**
 * Parses rhythm like 4,4,5 to array [4,4,4]
 */
export function parseRhythm(rawValue) {
  const rhythm = rawValue.trim().split(',');
  return rhythm.map(value => value && value.trim());
}

export function getADSRArray(adsrString) {
  if (!adsrString) return [];
  const adsrArray = adsrString.trim().split(',');
  return adsrArray.map(value => value.trim()).filter(value => value);
}

/**
 * Parses time signature like 3/4 to array [3,4]
 */
export function parseTimeSignature(rawValue) {
  if (!rawValue) return [];
  const timeSignature = rawValue.trim().split('/');
  // if no value is provided in denominator, default quarter note length is assumed
  if (timeSignature && timeSignature.length && timeSignature.length === 1) {
    return [parseInt(timeSignature[0], 0), 4];
  }
  if (
    timeSignature &&
    timeSignature.length &&
    timeSignature.length === 2 &&
    !timeSignature[1]
  ) {
    return [parseInt(timeSignature[0], 0), 4];
  }
  return timeSignature.map(value => parseInt(value, 0));
}

export function getRhythmStringFromTimeSignature(parsedTimeSignature) {
  if (!parsedTimeSignature) return '';
  if (!parsedTimeSignature[0]) return '';
  const newRhythm = Array(parsedTimeSignature[0]).fill(parsedTimeSignature[1]);
  return newRhythm.join(',');
}
